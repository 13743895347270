import { Component, CUSTOM_ELEMENTS_SCHEMA, inject, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CartFacade } from '@ev-portals/dp/frontend/purchase/data-access';
import { LoadingOverlayComponent } from '@ev-portals/ev/frontend/ui-library';
import { switchMap, take, throwError } from 'rxjs';

import { PurchaseService, Step } from '../purchase';

@Component({
  standalone: true,
  imports: [LoadingOverlayComponent, RouterModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './order-confirmation-page.component.html',
  styleUrls: ['./order-confirmation-page.component.scss'],
})
/**
 * This component is responsible for the redirect after a successful payment.
 */
export class OrderConfirmationPageComponent {
  #cartFacade = inject(CartFacade);
  #purchaseService = inject(PurchaseService);
  $loading = signal(true);
  $paymentSuccessful = signal(false);

  constructor() {
    this.#verify();
  }

  /**
   * Verify the payment
   */
  #verify() {
    this.#cartFacade
      .verifyPayment()
      .pipe(
        switchMap(res => {
          if (res.paymentSuccessful) {
            return this.#cartFacade.placeOrderAndCleanUp();
          }

          return throwError(() => 'Payment was not successfully placed');
        }),
        switchMap(res => {
          if (res.orderPlaced) {
            this.$paymentSuccessful.set(res.orderPlaced);
            this.#purchaseService.$currentStep.set(Step.Cart);
            return this.#cartFacade.loadCartData();
          }

          return throwError(() => 'Order could not placed');
        }),
        take(1),
      )
      .subscribe({
        next: () => {
          this.$paymentSuccessful.set(true);
          this.$loading.set(false);
        },
        error: err => {
          this.$paymentSuccessful.set(false);
          this.$loading.set(false);
          console.error(err);
        },
      });
  }
}
